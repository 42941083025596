import React from 'react';
import './TextBlock.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function TextBlock() {
  return (
    <div className='textBlock-container'>
        <div className='textBlock1'>
            <h2>At Stelko Services Inc, we know it’s impossible to be great at everything. Your expertise is in the development and production of your goods. That’s exactly what you should be great at!</h2>
        </div>
        <div className='textBlock2'>
            <h1>We help your business navigate all the obstacles from the time your products are manufactured until they are in the hands of the consumer.</h1>
        </div>

        
        <img src="images/halifaxHarbour.jpg" className="img-halifax" alt="boat"  />
        <div className='textBlock1'>
            <h2>Stelko Services Inc is a privately owned and operated business proudly located in the Atlantic provinces, along the eastern seaboard of Canada. With two major ocean ports and easy access to the United States and the rest of Canada, we are your supply chain experts. </h2>
        </div>
        <div className='textBlock2'>
            <h1>You CAN get there from here!</h1>
        </div>
        
    </div>
  );
}

export default TextBlock;