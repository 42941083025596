import React, { useEffect, useState } from 'react';
import './ServicesBlock.css';
import Faq from "react-faq-component";

const data = {
    title: "WHAT WE DO",
    rows: [
        {
            title: "SALES & BRAND RECOGNITION",
            content: `Our specialty is sales and assisting you in getting your product to market. Whether you want to expand your sales footprint locally, internationally, or globally, we can help. Our extensive network of buyers in the Atlantic Provinces, Ontario, Northeast United States, and Europe can quickly give your business an advantage over your competitors. No need for you to go knocking on doors that we have already opened. With an emphasis on B2B sales, we do all the legwork, and we are proud of the value we have been able to offer our clients.  
            <br/> <br/> Brand recognition is key to both new and recurring sales.  We have experience in introducing new products to market and creating a solid and familiar reputation with consumers.  Through collaboration with our clients, we have the ability to grow your brand into a household name. 
            `,
        },
        {
            title: "SHIPPING & LOGISTICS",
            content:
                `One of the most difficult aspects of a business is finding buyers for your goods. A very close second is getting your product shipped to buyers and fulfilling the last step of your purchase orders.
            <br/> <br/> Having the ability to deliver your product in a timely fashion is critical to your success, profitability, and maintaining solid customer relationships.  Given the current global instability in the shipping industry, securing trucks, drivers, containers etc, can be an ongoing challenge. Stelko Services Inc has loyal and dedicated freight partners, and we have goods on the move every day. If negotiating freight rates, dealing with customs restrictions and fees, and trying to understand confusing Incoterms have you overwhelmed, we can assist you so you can concentrate on doing what you do best.
                `,
        },
        {
            title: "DISTRIBUTION",
            content: `Sometimes, the most feasible method for a manufacturer to enter the supply chain or to increase overall revenue is to utilize a distributor or wholesaler. Stelko Services Inc is always willing to work with quality partners directly as a distributor or by facilitating and securing relationships with distribution firms that focus solely on your industry and offerings. `,
        },
        {
            title: "CONSULTING",
            content: `Often in business, it is easy to get so caught up in long days and hard work that you can’t see the forest for the trees so to speak. Our consulting services offer you a set of unbiased and fresh eyes on your operation. We can shed light on areas of concern that require improvement or action, or point out new methods of increasing revenues, decreasing costs, and saving you valuable time.

            <br/> <br/>    If you are in the early stages of your journey in business, or at a point where you are considering a change, we know how overwhelming that can feel. Stelko Services will thoroughly assess your situation and provide you the knowledge and information that you require to take action with confidence.
            `,
        },
    ],
};
 
const styles = {
    // bgColor: 'white',
    titleTextColor: "#242424",
    rowTitleColor: "#242424",
    rowContentColor: '#505050',
    // arrowColor: "red",
};
 
const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

function ServicesBlock() {
  return (
      <>
    <div className='servicesBlock-container'>
        <div className='services-card'> 
            <div>
                <Faq
                        data={data}
                        styles={styles}
                        config={config}
                />
            </div>
        </div>
    </div>
    
    </>
  );
}

export default ServicesBlock;