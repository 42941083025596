import React from 'react'
import { Button } from './Button'
import './HeroSection.css'
import '../App.css'


function HeroSection() {
    return (
        <div className='hero-container'>
            <video src="/videos/lighthouse.mov" autoPlay loop muted />
            <h1>CLIENT FOCUSED. RESULTS DRIVEN.</h1>
            <p>Stelko Services</p>
            <div className="hero-btns">
            </div>
        </div>
    )
}

export default HeroSection
