import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import './FormPage.css'
import { Button } from './Button';

const FormPage = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    alert("Message Sent, thank you!");

    emailjs.sendForm('service_lo3f63w', 'template_kx7utiy', form.current, 'user_rrfJCxrxPY3Qpk1TbwtyI')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <>
    <div class="form-container">    
        <div class="form-wrapper">
            
            <div class="form-item">
                <div>
                    <h3>GET IN TOUCH</h3>
                </div>
                <form ref={form} onSubmit={sendEmail} className="formItself">
                    
                    <div className="inputField-form">    
                        <input type="text" class="form-control" name="from_name" id="name" placeholder="Name"/>
                    </div>        
                        
                    <div className="inputField-form">   
                        <input type="email" class="form-control" name="user_email" id="email" placeholder="Email"/>
                    </div>        
                    <div className="inputField-form">        
                        <input type="text" class="form-control" name="company_name" id="company" placeholder="Company"/>
                    </div>        
                    <div className="inputField-form">       
                        <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject"/>
                    </div>        
                    <div className="inputField-form">    
                        <textarea name="message" class="form-control" id="message" cols="30" rows="7" placeholder="Message"></textarea>
                    </div>        
                    <div className="inputField-form">
                        <input type="submit" value="Send Message" class="btn btn--outline"/>
                        
                    </div>  
                    
                    
                  
                </form>
            </div>       
            
               
            <div className="contactUsSection">
                <h3>CONTACT US</h3>
                <div className="contactFields">
                    
                    
                    <div className="contactRow">
                        <div className="contactIcon">
                            <span class="fa fa-map-marker"></span>
                        </div>
                        <div>
                            <p><span>Address: </span>65 Fairway Blvd. Riverview, NB, CA</p>
                        </div>
                    </div>
                    <div className="contactRow">
                        <div className="contactIcon">
                            <span class="fa fa-phone"></span>
                        </div>
                        <div>
                            <p><span>Phone:</span> <a href="tel://15068746807">+1 (506) 874-6807</a></p>
                        </div>
                    </div>
                    <div className="contactRow">
                        <div className="contactIcon">
                            <span class="fa fa-paper-plane"></span>
                        </div>
                        <div>
                            <p><span>Email: </span> <a href="mailto:shaun@stelkoservices.com">shaun@stelkoservices.com</a></p>
                        </div>
                    </div>
                    <div className="contactRow">
                        <div className="contactIcon">
                            <span class="fa fa-globe"></span>
                        </div>
                        <div>
                            <p><span>Website: </span> <a href="#">stelkoservices.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
                    
        </div>
    </div>
        
    

    </>
  );
};

export default FormPage;