import './App.css';
import FormPage from './components/FormPage';
import NavBar from './components/NavBar';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import SignUp from './components/pages/SignUp';
import Footer from './components/Footer';
import TextBlock from './components/TextBlock';
import ServicesBlock from './components/ServicesBlock';
import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path='/services' element={<> <Services /> <ServicesBlock /> </>}></Route>
          <Route path='/about-us' element={<> <Products /> <TextBlock /> </>}></Route>
          <Route path='/sign-up' element={<> <SignUp /> <FormPage /> </>}></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
