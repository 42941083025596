import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import CardItem2 from './CardItem2';

function Cards() {
  return (
    <div className='cards'>
      
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem2
              src='images/lighthouse3.jpg'
              text='Get to know our organization'
              label='Who We Are'
              path='/about-us'
            />
            <CardItem
              src='images/convention.jpg'
              text='Learn more about how Stelko can help develop your business'
              label='What We Do'
              path='/services'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;